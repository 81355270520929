<template>
  <div class="register-container">
    <div class="header">
      <el-icon @click="handleCancel"><ArrowLeft /></el-icon>
      <span> {{ t('register') }}</span>
    </div>
    <el-form
      ref="registerFormRef"
      class="register-form"
      :model="registerForm"
      :rules="registerRules"
    >
      <el-form-item prop="username" :label="t('user_name')">
        <el-input
          :placeholder="t('user_name')"
          v-model="registerForm.username"
          name="username"
          type="text"
        >
        </el-input>
      </el-form-item>

      <el-form-item prop="password" :label="t('password')">
        <el-input
          :placeholder="t('password')"
          v-model="registerForm.password"
          name="password"
          type="password"
        >
        </el-input>
      </el-form-item>

      <el-form-item prop="siteName" :label="t('site_name')">
        <el-input
          :placeholder="t('site_name')"
          v-model="registerForm.siteName"
          name="siteName"
          type="text"
        >
        </el-input>
      </el-form-item>

      <el-form-item prop="siteLocation" :label="t('location')">
        <el-input
          :placeholder="t('location')"
          v-model="registerForm.siteLocation"
          name="siteLocation"
          type="text"
        >
        </el-input>
      </el-form-item>
      <div style="display: flex; flex-direction: column">
        <el-checkbox
          v-model="registerForm.isApprovePDLicense"
          :label="t('pd_pay')"
          size="large"
          style="margin: 15px 0"
        />
        <el-form-item prop="email" label="Email">
          <el-input placeholder="Email" v-model="registerForm.email" name="email" type="text">
          </el-input>
        </el-form-item>
      </div>
      <!-- <el-form-item prop="isApprovePDLicense" class="is-approve-pd-license">
        <el-checkbox v-model="registerForm.isApprovePDLicense" label="PD License" size="large" />
      </el-form-item> -->
      <div style="width: 50%"></div>
      <div class="handle-btn">
        <el-button class="register-btn" type="primary" @click="handleRegister">{{
          t('submit')
        }}</el-button>
      </div>
    </el-form>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { validateEmail, validatePassword, validateUserName } from '@/views/login/rule'
import { useRouter } from 'vue-router'
import { createNewUsers } from '@/api/user'
import { ElMessage } from 'element-plus'
import { ArrowLeft } from '@element-plus/icons-vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const registerFormRef = ref(null)

/**
 * @description: 数据源
 */
const registerForm = ref()
registerForm.value = {}
registerForm.value.isApprovePDLicense = false

/**
 * @description: 验证规则
 */
const registerRules = ref({
  username: [
    {
      required: true,
      trigger: 'blur',
      validator: validateUserName()
    }
  ],
  password: [
    {
      required: true,
      trigger: 'blur',
      validator: validatePassword()
    }
  ],
  email: [
    {
      required: true,
      trigger: 'blur',
      validator: validateEmail()
    }
  ]
})

const handleRegister = () => {
  registerFormRef.value.validate(async valid => {
    if (!valid) return
    const registerData = {
      approvalstatus: 0,
      email: registerForm.value.email,
      isApprovePDLicense: Number(registerForm.value.isApprovePDLicense),
      location: registerForm.value.siteLocation,
      password: registerForm.value.password,
      userId: registerForm.value.username
    }
    try {
      await createNewUsers(registerData)
      registerFormRef.value.resetFields()
      await router.push({ path: '/registerTip' })
    } catch (e) {
      console.error(e)
      ElMessage({
        message: t('register') + t('error'),
        grouping: true,
        type: 'error',
        customClass: 'divSuccessElMessage'
      })
    }
  })
}

const router = useRouter()

const handleCancel = () => {
  registerFormRef.value.resetFields()
  router.push({ path: '/login' })
}
</script>

<style lang="scss" scoped>
$cursor: #fff;

.register-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    display: flex;
    align-items: center;
    padding: 32px;
    width: 100%;
    .el-icon {
      padding: 14px;
      color: var(--icon-color);
      width: 52px;
      height: 52px;
      border: 2px solid var(--border-el-primary);
      border-radius: 4px;
      cursor: pointer;
      .icon {
        width: 24px;
        height: 24px;
      }
    }
    span {
      font-size: 30px;
      color: var(--font-color-active);
      letter-spacing: 0;
      line-height: 30px;
      font-weight: 700;
      margin-left: 32px;
    }
  }

  .register-form {
    position: relative;
    width: 1056px;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .handle-btn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 72px;

      .register-btn {
        width: 240px;
        height: 66px;
      }
    }

    ::v-deep .el-form-item {
      flex-direction: column;
      align-items: flex-start;
      .el-form-item__content {
        width: 100%;
        font-size: 20px;
      }
      .el-form-item__label {
        font-size: 20px;
      }
    }

    ::v-deep .el-input {
      display: inline-block;
      height: 47px;
      width: 512px;
      height: 66px;

      .el-input__inner {
        height: 66px;
        background-color: rgba(255, 255, 255, 0.1);
        border: 1px solid transparent;
        border-radius: 4px;
        -webkit-appearance: none;
        caret-color: $cursor;
      }
    }
    ::v-deep .el-checkbox {
      display: flex;
      align-items: center;
    }
    ::v-deep .el-checkbox__inner {
      width: 32px;
      height: 32px;
    }
    ::v-deep .el-checkbox__label {
      font-size: 20px;
    }
  }
}

@media (max-width: 1440px), (max-height: 937px) {
  .register-container {
    .register-form {
      ::v-deep .el-input {
        height: 47px;
        .el-input__inner {
          height: 47px;
        }
      }
      ::v-deep .el-form-item .el-form-item__content,
      ::v-deep .el-form-item .el-form-item__label {
        font-size: 14px;
      }
      ::v-deep .el-checkbox__inner {
        width: 14px;
        height: 14px;
      }
      ::v-deep .el-checkbox__label {
        font-size: 14px;
      }
      .handle-btn {
        .register-btn {
          height: 40px;
        }
      }
    }
  }
}
</style>
